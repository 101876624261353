import React, { useState, useEffect } from 'react'
import {withRouter} from "react-router-dom"
import {FormattedMessage as Fm} from "react-intl"
import * as selectors from "../redux/selectors"
import { connect } from "react-redux";
import {makeStyles} from "@material-ui/styles"
import styled from "styled-components";
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    color: theme.palette.primary.contrastText,
  },
  thankYouContainer: {

  },
}));
function ThankYouPage(props) {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Typography variant="h2">
        <Fm id="emailForm.thankYou.title" />
      </Typography>
      <Typography align="center" variant="subtitle1">
        <Fm id="emailForm.thankYou.text" />
      </Typography>
    </Container>
  )
}

export default withRouter(ThankYouPage)