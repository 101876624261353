import { CHANGE_LANGUAGE, QUESTIONS_LOADED, NEXT_QUESTION, SKIP_QUESTION, FINISHED, ANSWER_QUESTION, ADD_QUESTION, ADD_INDIVIDUAL_QUESTION, PREV_QUESTION, CHANGE_PAGE } from "./actionTypes";
import * as constants from "constants"
import { v4 as uuid } from 'uuid';
import * as selectors from "./selectors"
import { sendToBack} from "../remote"
const initialState = {
  language: constants.DE,
  allIds: [],
  byIds: {},
  finished: false,
  questions: [],
  questionHistory: [], // for History
  // contains {answerId, nextId}
  questionQueue: [], // questions todo
};


export default function(state = initialState, action) {
  const isAkkordeon = (qId)=> {
    return selectors.getQuestionById(state, qId).akkordeonId
  }
  switch (action.type) {
    case QUESTIONS_LOADED: {
      return {
        ...state,
        questionHistory: ["Q0001"]
      }
    }
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: state.language === constants.DE ? constants.EN : constants.DE
      }
    }
    case FINISHED: 
      return {
        ...state,
        finished: true,
      }
    case ADD_QUESTION: {
      const { question } = action.payload;
      return {
        ...state,
        questions: [...state.questions, question],
        allIds: [...state.allIds, question.id],
        byIds: {
          ...state.byIds,
          [question.id]: {
            ...question
          }
        }
      };
    } break;
    case PREV_QUESTION: {
      let questionHistory = [...state.questionHistory]
      let newByIds = {...state.byIds}
      let currentId = questionHistory.pop()
      const isAkkordeonHere = () => isAkkordeon(currentId)
      const updateHistory = (id)=> {
        // Email is not purged
        if (!id.includes("END")) {
          newByIds = {
            ...newByIds,
            [id]: {
              ...newByIds[id],
              answeredId: undefined
            }
          }
        }

        console.log(newByIds)
      }
      // For akkordeons skip many
      if (isAkkordeonHere()) {
        while(isAkkordeonHere()) {
          updateHistory(currentId)
          currentId = questionHistory.pop()
        }
        questionHistory.push(currentId)
      } else {
        updateHistory(currentId)
      }


      // Put it on stack again
      // For now don't save previously answered questions
      // if (!selectedItem.includes("END")) {
      //   newQuestionQueue = [...state.questionQueue, {answerId: "", nextId: selectedItem}]
      // }

      return {
          ...state,
          questionHistory,
          byIds: newByIds,
          // Erase questions in queue
          questionQueue: []
      }
    } break;
    case NEXT_QUESTION: {
      const currentQuestions = selectors.getCurrentQuestions(state)
      if (state.questionQueue.length > 0) {
        let questionQueue = [...state.questionQueue]
        const selectedItem = questionQueue.pop()

        return {
            ...state,
            questionHistory: [...state.questionHistory, selectedItem.nextId],
            questionQueue: questionQueue.filter(item=>item.nextId !== selectedItem.nextId) // Remove all duplicates
        }
      } else {
          const firstUnanswered = currentQuestions.find(q=>!Boolean(q.answeredId))
          const nextId = selectors.getNextQuestionId(state);
          console.log(firstUnanswered, nextId)
          // in case for akkordeon
          const realNextId = currentQuestions.length>1 && firstUnanswered ? firstUnanswered.id : nextId
          return {
            ...state,
            questionHistory: [
              // Erase duplicates
              ...state.questionHistory.filter(id=>id !== realNextId), 
              realNextId
            ],
          }
        
      }

      return state;
    } break;
    case SKIP_QUESTION: {
      // Skip Id of leader
      const skipId = selectors.getSkippedQuestionId(state);
      const newByIds = {...state.byIds}

      if (skipId) {
        return {
            ...state,
            questionHistory: [...state.questionHistory, skipId],
            byIds: newByIds
        }
      }
      return state;
    } break;
    case ANSWER_QUESTION: {
      const { questionId, answerId: answerKeyMap } = action.payload;
      const question = selectors.getQuestionById(state, questionId)
      const currentQuestions = selectors.getCurrentQuestions(state)
      const currentQIds = currentQuestions.map(q=>q.id)
      let questionQueue = [...state.questionQueue]
      if (question.type.toLowerCase() === "multipleselect") {
        // Requirements:
        // override von anderer answer && selbe nextId nicht möglich
        // override von selber answer möglich

        // Object form enables to have the same nextId from multiple questions
        const questionQueueObj = {
          ...state.questionQueue.reduce((acc, cur) => ({ ...acc, [cur.answerId]: cur }), {}),
          ...answerKeyMap // Overrides prev answers with false
        }

        questionQueue = Object.keys(questionQueueObj)
        .filter(aId=>Boolean(questionQueueObj[aId])) // only take truthy values, false not needed anymore
        .map(aId=> typeof questionQueueObj[aId] === "boolean" ? {
            nextId: question.answers.find(a=>a.id === aId).nextId || question.nextId,
            answerId: aId,
        }: questionQueueObj[aId]) // Get new answers through aId
        .filter(a=>!currentQIds.includes(a.nextId))
        .filter(a=> !a.nextId.includes("END")) // Filter out Ending screens in queue
      }
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [questionId]: {
            ...state.byIds[questionId],
            answeredId: answerKeyMap
          }
        },
        questionQueue
      };
    }
    default:
      return state;
  }
}
