import { NEXT_QUESTION, QUESTIONS_LOADED, ANSWER_QUESTION, ADD_QUESTION, ADD_INDIVIDUAL_QUESTION, CHANGE_LANGUAGE, CHANGE_PAGE, PREV_QUESTION, FINISHED, SKIP_QUESTION } from "./actionTypes";

export const setQuestionsLoaded = () => ({
  type: QUESTIONS_LOADED
});

export const finish = () => ({
  type: FINISHED
});

export const prevQuestion = (id) => ({
  type: PREV_QUESTION,
  id
});

export const addQuestion = (question) => ({
  type: ADD_QUESTION,
  payload: {
    question,
  }
});

export const nextQuestion = () => ({
  type: NEXT_QUESTION,
});

export const answerQuestion = (questionId, answerId) => ({
  type: ANSWER_QUESTION,
  payload: {
    questionId,
    answerId
  }
});

export const skipQuestion = () => ({
  type: SKIP_QUESTION,
  payload: {
  }
});

export const addIndividualAnswer = (id, individualAnswer) => ({
  type: ADD_INDIVIDUAL_QUESTION,
  payload: {
    id,
    individualAnswer
  }
});

export const changeLanguage = () => ({
   type: CHANGE_LANGUAGE,
   payload: {}
 });

 export const changePage = (page) => ({
    type: CHANGE_PAGE,
    page
  });
