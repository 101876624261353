import { Answer, Question, Lang} from "./redux/constructors";
import questions from "data/questions.json";
export const loadQuestions = ({setQuestionInStore, finished}) => {
    for (const q of questions) {
      if (q.id) {
        const answers = q.answers ? q.answers.filter(an=> an.id) : [];
        setQuestionInStore({...q, answers})
      }
    }
    finished(true)
  }