import { createSelector } from 'reselect'
export const getCurrentLanguage = store => store.language;
export const getQuestionsState = store => store
export const getQuestionById = (qState, id) => id ? ({...qState.byIds[id]}) : undefined
export const getPrevQuestionById = (qState, id) => ({...qState.byIds[id]})
export const getNextQuestionIdFromQuestion = q => {
  if (q.nextId) 
    return q.nextId
  if (q.answeredId) {
    const answerNextId = q.answers.find(a=>a.id === q.answeredId).nextId
    return (answerNextId && answerNextId.length > 0) ? answerNextId : "email"
  }
  return "email"
}
export const getPossibleNextQuestionIdsFromQuestion = q => {
  if (q.nextId) 
    return [q.nextId]
  return q.answers ? q.answers.map(a=>a.nextId).reduce((arr,curr)=>arr.includes(curr) ? arr : [...arr,curr], []) : []
}
export const getQuestionsByStateAndIds = (qState, ids) => ids.map(id => getQuestionById(qState,id))
export const getAkkordeonQuestionsByAkkordeonId = (akkordeonId, qs) => akkordeonId ? qs.filter(q=>Boolean(q.akkordeonId) && q.akkordeonId === akkordeonId) : undefined


export const makeGetPossibleNextQuestionsFromQuestion = (q) => createSelector(getQuestionsState, (state) => getQuestionsByStateAndIds(state,getPossibleNextQuestionIdsFromQuestion(q)))
export const getAllQuestionIds = createSelector(getQuestionsState,(qState) => qState.allIds || [])
export const makeGetQuestionById = (id) => createSelector(getQuestionsState, (state) => getQuestionById(state,id))
export const getAllQuestions = createSelector(getQuestionsState, getAllQuestionIds, (qState, ids) => ids.map(id => getQuestionById(qState,id)))
export const getCurrentQuestionId = createSelector(getQuestionsState, qState => qState.questionHistory[qState.questionHistory.length -1]);
export const getCurrentQuestion = createSelector(getQuestionsState, getCurrentQuestionId, getQuestionById);
export const getIsFirst = createSelector(getQuestionsState, qState => qState.questionHistory.length === 1)
export const getHistory = createSelector(getQuestionsState, qState => qState.questionHistory)

export const getCurrentAnswer = createSelector(getCurrentQuestion, q => q.answers.find(a=>a.id ===q.answeredId));
export const getCurrentAkkordeonId = createSelector(getCurrentQuestion, q => q ? q.akkordeonId : undefined);
// TODO order is not guaranteed
export const getAkkordeonQuestions = createSelector(getCurrentAkkordeonId, getAllQuestions, getAkkordeonQuestionsByAkkordeonId)
// ALso includes skipped
export const getAnsweredQuestions = createSelector(getAllQuestions, questions => questions.filter(question => question.answeredId))
export const getAnsweredIds = createSelector(getAnsweredQuestions, questions => questions.map(q=>q.id))
export const getCurrentQuestions = createSelector(getAkkordeonQuestions, getCurrentQuestion, (akkordeonQuestions, question) => {
  if (akkordeonQuestions && akkordeonQuestions.length > 1) {
    return akkordeonQuestions
  } else if (question) {
    return [question]
  }
  return []
})
export const getCurrentQuestionIds = createSelector(getCurrentQuestions, questions => questions.map(q=>q.id))

export const getNextQuestionId = createSelector(getCurrentQuestion, getNextQuestionIdFromQuestion);

export const getProgress = createSelector(getQuestionsState, getHistory, getCurrentQuestion,getIsFirst, (state,history, q, isFirst) => {
  const iteratedQuestionIds = []
  const recursiveNextQ = (cq) => {
    let maxCountNext = 0
    let baseCount = 0

    // avoid multiple count for merging paths
    if (cq && !cq.id.includes("END")) {
      baseCount = 1
      if (!iteratedQuestionIds.includes(cq.id)) {
        iteratedQuestionIds.push(cq.id)
        // Calculate Base Count
  
        // baseCount = cq.answers.length
  
        // Calculate Next Count
        const nextQs = makeGetPossibleNextQuestionsFromQuestion(cq)(state)
        for (let nextQ of nextQs) {
          maxCountNext = Math.max(recursiveNextQ(nextQ), maxCountNext)           
        }
      }
    }

    return baseCount + maxCountNext
  }
  if (isFirst) {
    return 0
  }
  const possibleFutureProgressCount = recursiveNextQ(q)
  console.log(possibleFutureProgressCount)
  const progressUntillHere = history.length
  const progressTotal = progressUntillHere + possibleFutureProgressCount

  return Math.round(Math.min(progressUntillHere / progressTotal * 100, 100))
});


export const getSkippedQuestionId = createSelector(getCurrentQuestions, qs => {
  const firstQ = qs[0]
  return firstQ.skipId || "email"
});