import React, {useState, useEffect} from "react";
import {FormattedMessage as Fm} from "react-intl";


import styled from "styled-components";
import {withStyles, makeStyles} from "@material-ui/styles"
import {
  Box,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core';

import {
ExpandMore,
CheckCircle
} from '@material-ui/icons';
// COMP
import {QuestionContent} from "./Question";
import {getHasAnswer} from "./index.js"
// STYLE
const Wrapper = styled.div`
    flex: 1; 
    display: flex;
    flex-direction: column;
    min-height: 0;
`
const useStyles = makeStyles(theme => ({
  accordion: {
    width: "100%",
    "& .MuiAccordionSummary-root": {
      padding: theme.spacing(0, 3),
    },
    "& .MuiCollapse-container": {
      padding: theme.spacing(0, 1)
    },
  }
}))

// Akkordeon Elements MUST follow each other AND have the same skip_id
const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',

      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        "&:first-child": {
          borderTop: "none"
        },
        margin: 'auto',
      },
    },
    expanded: {},
    answered: {}
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      // borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      '&$expanded, &$answered': {
        minHeight: 56,
        background: "transparent",
        border: "none"
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
    answered: {}
  })(MuiAccordionSummary);

const Akkordeon = ({category, questions, lan}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(questions[0].id);
  const handleChange = (questionId) => (event, newExpanded) => {
      setExpanded(newExpanded ? questionId : false);
  };
  const getUnansweredQLength = () => questions.filter(q=>!getHasAnswer([q])).length
  const getFirstNotAnsweredQ = () => questions.find(q=>!getHasAnswer([q]))

  useEffect(() => {
    const firstNotAnswered = getFirstNotAnsweredQ()
    if (firstNotAnswered && (firstNotAnswered.id !== expanded)) {
      setExpanded(firstNotAnswered.id)
    }
  }, [questions])
    return <Wrapper>
        {questions.map(question => <Accordion
          className={classes.accordion}
          square  
          expanded={expanded === question.id} 
          answered={getHasAnswer([question])} 
          onChange={handleChange(question.id)}
        >
            <AccordionSummary 
               expandIcon={<ExpandMore />}
            >
              <Box pr={1}>
                <Typography variant="h6">
                    <Fm id={`question.${question.id}.title`} />
                </Typography>
              </Box>
              <div style={{minWidth: 24}}>
                {getHasAnswer([question])  && <CheckCircle color="primary" />}
              </div>
            </AccordionSummary>
            <AccordionDetails>
                <QuestionContent
                    id={question.id}
                    type={question.type}
                    answers={question.answers}
                    answeredId={question.answeredId}
                    explanation={question.explanation}
                    isAkkordeonElement={Boolean(getUnansweredQLength() > 1)}
                />
            </AccordionDetails>
        </Accordion>)}
    </Wrapper>
}

export default Akkordeon;