export const SET_ACTIVE_QUESTION = "SET_ACTIVE_QUESTION";
export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const ADD_INDIVIDUAL_QUESTION = "ADD_INDIVIDUAL_QUESTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const PREV_QUESTION = "PREV_QUESTION";
export const FINISHED = "FINISHED";
export const SKIP_QUESTION = "SKIP_QUESTION";
export const NEXT_QUESTION = "NEXT_QUESTION";
export const QUESTIONS_LOADED = "QUESTIONS_LOADED";
