import React, { useState, useEffect } from 'react'
import {FormattedMessage as Fm } from "react-intl"
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import CookieConsent from "components/CookieConsent";
import './App.css'
import * as constants from "constants"
import {loadQuestions} from "QuestionsLoader";
import Footer from 'components/Footer'
import Content from 'pages/Content'
import ResultScreen from "pages/ResultPage"
import { connect } from "react-redux";
import { changeLanguage, addQuestion, answerQuestion, setQuestionsLoaded } from "./redux/actions";
import { getCurrentLanguage } from "./redux/selectors";
import styled from "styled-components";
import {useIsMobile} from "./hooks"
import ProtosLogo from "assets/PROTOS_Logo.svg"
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Switch,
  Route,
} from "react-router-dom";

// COMP
import LandingPage from './pages/LandingPage';
import ResultPage from './pages/ResultPage';
import ThankYouPage from './pages/ThankYouPage';

// STYLES
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,

} from '@material-ui/core';
import {
  Home,
  Menu as MenuIcon
} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  languageButton: {
    spacing: 10
  },
  mainWrapper: {
    minHeight: "100%",
    position: "absolute",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
    // [theme.breakpoints.down('sm')]: {
    //   padding: "0 2%"
    // }
  },
  mainContainer: {
    flex: 1,
    width: "70%",
    maxWidth: 800,
    [theme.breakpoints.down('xs')]: {
      width: "95%",
    }
  },
  appBar: {
    background: "transparent",
    boxShadow: "none",
    color: "white",
    paddingTop: "10px"
  },
  menuButton: {
    width:30,
    height: 30,
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40
    }
    // marginRight: theme.spacing(2),
  },
  title: {
    textAlign: "center"
  },
  toolbar: {
    // margin: "0 " + theme.spacing(2) + "px",
  },
  logo: {

  },
}));

  const TRACKING_ID = "UA-155093630-2";
  // const progressPercent =  props.page === 999 ? 100 : (props.page === maximumPage ? 95 : Math.ceil(((questionsAnswered / allQuestions.length) * 100)/5)*5)

  const MainWrapper = styled.div`
    display: flex;
    flex-direction:column;
    overflow: hidden;
  `

  const MainContainer = styled.div`
    display: flex;
    flex-direction:column;
  `
  const Header = styled.div`
    height: 100px;
    display: flex;
    background: #029ddb;
  `

  const StyledLogo = styled(ProtosLogo)`
    * {
      fill: white;
    }
    width: 250px;
  `
  const LanguageButton = styled(Button)`
  spacing: 10px;
  p,b {
    margin-right: 3px
  }
`

export const App = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    window.onbeforeunload = props.finished ? null : function() {
        return true;
    };

    return () => {
        window.onbeforeunload = null;
    };
  }, [props.finished]);
  useEffect(() => {
    try {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } catch(err) {
      console.log(err)
    }
    if (!props.questionsLoaded) {
      loadQuestions({
        setQuestionInStore: props.addQuestion,
        finished: () => props.setQuestionsLoaded()
      });
    }
  })


  const getLanguageString = () => {
    const Wrapper = styled.div`
      display: flex;
      align-items: center;
    `
    switch (props.language) {
      case constants.EN: return <Wrapper><p>DE</p><p>|</p><b>EN</b></Wrapper>
      default: return <Wrapper><b>DE</b><p>|</p><p>EN</p></Wrapper>
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Scrollbars>

    <MainWrapper
      className={classes.mainWrapper}
    >
      <MainContainer
        className={classes.mainContainer}
      >
        <AppBar color="transparent" position="static" className={classes.appBar}>
          <Toolbar
            disableGutters
            className={classes.toolbar}
          >
          <StyledLogo
            // className={classes.logo}
            style={{cursor: "pointer"}}
            onClick={() => history.push("/")}
          />
            <div style={{flex: 1}} />
            {!isMobile && <LanguageButton 
              data-testid="languagebutton"
                  // GA: track user changed Language by click button
                  onClick={() => {props.changeLanguage(); ReactGA.event({category: "changedLanguage",action: "Language Button clicked",label: "User clicked Button to change Language"})}}
                  color="inherit"
            >
              {getLanguageString()}
            </LanguageButton>
            }
            <IconButton
              color="inherit"
              size="medium"
              disableRipple
              aria-label="menu"
              aria-controls="simple-menu"
              onClick={handleClick}
            >
              <MenuIcon
                className={classes.menuButton}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Switch>
          <Route path="/main">
            <Content />
          </Route>
          <Route path="/thankyou">
            <ThankYouPage />
          </Route>
          <Route path="/result">
            <ResultPage />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>

      </MainContainer>
      <Footer
        language={props.language}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isMobile && <MenuItem onClick={() => {
          props.changeLanguage(),
          handleClose(),
          // GA: track user changed Language by click button
          ReactGA.event({category: "changedLanguage",action: "Language Button clicked",label: "User clicked Button to change Language"})
        }}>
          {getLanguageString()}
        </MenuItem>}
        <MenuItem onClick={()=> {
          window.open("/", "_self")
          handleClose()
        }}
        ><Fm id="global.reset" /></MenuItem>
        <MenuItem onClick={()=> {
          window.open("https://www.protos-technologie.de/impressum/", "_blank")
          handleClose()
        }}
        ><Fm id="footer.impressum.title" /></MenuItem>
      </Menu>
      <CookieConsent />
    </MainWrapper>
     </Scrollbars>
  )
}

const mapStateToProps = state => {
  const language = getCurrentLanguage(state);

  return { language, questionsLoaded: state.questions.length > 0, finished: state.finished };
};
export default connect(
  mapStateToProps,
  { changeLanguage, addQuestion, answerQuestion, setQuestionsLoaded }
)(App);
