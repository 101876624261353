import React, { useState, useEffect } from 'react'
import {useIsMobile} from "./../../hooks"

import './../../App.css'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import cn from "classnames";
import styled from "styled-components";
import {
  Typography,
  StepLabel,
  Step,
  Stepper,
  Grid,
  CircularProgress,
  Box
} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudIcon from '@material-ui/icons/Cloud';
import SecurityIcon from '@material-ui/icons/Security';
import GavelIcon from '@material-ui/icons/Gavel';
import GroupIcon from '@material-ui/icons/Group';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TrainIcon from '@material-ui/icons/Train';
import CALogo from 'assets/CLOUD_ADVISOR_with_Claim_white.svg';

// COMP
import CircularProgressWithLabel from "./ProgressWithLabel"


// STYLES
const useStyles = makeStyles((theme) => ({
  mobileNav: {
    // background: "#e3e3e3",
    color: "rgba(0, 0, 0, 0.54)",
    display: "flex", 
    alignItems:"center", 
    flex: 1
  },
  circle: {
    position:'absolute',
    left: 0,
    zIndex: 3,
    color: "white",
    fontSize: "120%"
  },
  progressText: {
    color: "white",
    fontSize: "120%"
  },
  CALogoPrimaryText: {
    
  }
}));
const NavWrapper = styled.div`
heigth: 80px;
display: flex;
width: 100%;
align-items: center;
`;

function Navigation({progress, ...props}) {
  const classes = useStyles();
  const categories = [
    {de: "Cloudtechnologie", en: "Cloud Technology", icon: <CloudIcon /> },
    {de: "Governance", en: "Governance", icon: <GavelIcon />},
    {de: "Menschen & Prozesse", en: "People & Processes", icon: <GroupIcon /> },
    {de: "Betrieb & Plattform", en: "Platform & Operations", icon: <TrainIcon /> },
    {de: "Sicherheit", en: "Security", icon: <SecurityIcon /> },
    {de: "Kontaktdaten", en: "Contacts", icon: <ContactMailIcon /> }
  ]
  const [progressHere, setProgressHere] = useState(0);
  const lanKey = props.language === "german" ? "de" : "en";
  const isMobile = useIsMobile();
  console.log(progress)

  useEffect(()=> {
    setProgressHere(progress)
  },[progress])

  return (
    <NavWrapper>
      <CircularProgressWithLabel
        classes={classes}
        progressPercent={progressHere}
      />
      <Box flexGrow={1} />
      <CALogo
        viewBox="0 0 171 45"
        style={{
          maxWidth: "300px",
          width: "68%"
        }}
      />
    </NavWrapper>
  )
}

export default Navigation;