import React, { useEffect, useState } from 'react'
import { FormattedMessage as Fm } from "react-intl";
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import './../App.css'
import Button from '@material-ui/core/Button';
import {COOKIE_CHECKED_SERVICES_NAME, FUNC_CATEGORY, GA_ID, YOUTUBE_ID} from "constants";
import {
	Typography,
	Divider,
	Slide,
	Switch,
	Snackbar,
	FormControl,
	FormHelperText,
	FormControlLabel,
	IconButton,
	FormGroup,
	FormLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Paper
} from '@material-ui/core';
import {
	Close,
	ExpandMore
} from '@material-ui/icons';

import MuiLink from '@material-ui/core/Link';
import styled from "styled-components";
import * as constants from "../constants"
import { useIsMobile } from "./../hooks"
import { makeStyles } from '@material-ui/core/styles';

function SlideTransition(props) {
	return <Slide 
	{...props} 
	style={{
		width: "100%"
	}}
	direction="up" />;
}

const Link = styled(MuiLink)`
    text-decoration: none;
    color: white;
`
const useStyles = makeStyles((theme) => ({
	buttonBox: {
		display: "flex",
		justifyContent: "flex-end",
		[theme.breakpoints.down('sm')]: {
			justifyContent: "center",
		}
	},
	snack: {
	},
	mainAccordion: {
		"&::before": {
			height: 0
		},
		margin: 0,
		maxWidth: "100%"
	},
	serviceAccordion: {
		minWidth: 300,
		maxWidth: 800
	},
}));
const Wrapper = styled.div`
`

const consentCategories = [
	{id: FUNC_CATEGORY, services: [
		{id: YOUTUBE_ID},
		{id: GA_ID},
	]}
]

function CookieConsent({cookies, ...props}) {
	const classes = useStyles();
	const cookie = cookies.get(COOKIE_CHECKED_SERVICES_NAME)
	const [open, setOpen] = useState(!Boolean(cookie))
	const [checkedServices, setCheckedServices] = useState([])

	const [expandedCategory, setExpandedCategory] = useState();
	const [expandedService, setExpandedService] = useState();

	const isChecked = (compItemId, isCategory = false) => {
		const isIncluded = (serviceId) => checkedServices.findIndex(itemId => itemId === serviceId) > -1
		if (isCategory) {
			const serviceIds = consentCategories.find(c=>c.id ===compItemId).services.map(s=>s.id)
			return serviceIds.every(sId=>isIncluded(sId))
		}
		return isIncluded(compItemId) 
	}

	const handleCheck = (itemId, isCategory = false) => {
		const getServiceIds = () => consentCategories.find(c=>c.id ===itemId).services.map(s=>s.id)
		if (isChecked(itemId, isCategory)) {
			if (isCategory) {
				const serviceIds = getServiceIds()
				// Remove all services
				return setCheckedServices([...checkedServices.filter(sId => !serviceIds.includes(sId))])
			}
			return setCheckedServices([...checkedServices.filter(sId => sId !== itemId)])
		} else {
			if (isCategory) {
				const serviceIds = getServiceIds()
				console.log(consentCategories.find(c=>c.id ===itemId).services.map(s=>s.id))
				// Add all services
				return setCheckedServices([...checkedServices.filter(sId => !serviceIds.includes(sId)), ...serviceIds])
			}
			return setCheckedServices([...checkedServices, itemId])
		}
	}

	const handleClose = (event, reason, all) => {
		if (reason === 'clickaway') {
			return;
		}
		if (all) {
			cookies.set(COOKIE_CHECKED_SERVICES_NAME, JSON.stringify(consentCategories.reduce((a,c) => [...a, ...c.services.map(s=>s.id)], [])));
		} else {
			cookies.set(COOKIE_CHECKED_SERVICES_NAME, JSON.stringify(checkedServices));
		}
		setOpen(false);
	};
	const handleChange = (panel, isCategory) => (event, isExpanded) => {
		const setFunction = isCategory ? setExpandedCategory : setExpandedService
		setFunction(isExpanded ? panel : false);
	};
	return (
		<Snackbar
			open={open}
			onClose={handleClose}
			TransitionComponent={SlideTransition}
			className={classes.snack}
			>
			<Paper
			elevation={6}
			>
				<Box
				p={3}
				>
				<Typography 
					variant="h6"
					gutterBottom
				>
					<Fm id={`popup.title`} />
				</Typography>
				<Typography 
					variant="subtitle1"
					gutterBottom
				>
					<Fm 
						id="popup.consentArea.explanation" 
						values={{
							privacyLink: ""
						}}
					/>
				</Typography>
				{consentCategories.map(category => 
				<Accordion
					elevation={0}
					expanded={expandedCategory === category.id} 
					onChange={handleChange(category.id, true)}
					className={classes.mainAccordion}
					id={category.id}
				>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						style={{padding: 0}}
					>
						<FormControlLabel
							onClick={(event) => event.stopPropagation()}
							onFocus={(event) => event.stopPropagation()}
							onChange={(e) => {
								e.stopPropagation();
								handleCheck(category.id, true)
							}}
							control={
								<Switch
									checked={isChecked(category.id, true)}
									name="checkedB"
									color="primary"
								/>
							}
							label={<Fm id={`popup.consentArea.${category.id}.title`} />}
						/>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: "flex",
							flexDirection: "column",
							padding: 0
						}}
					>
						<Typography gutterBottom>
							<Fm id={`popup.consentArea.category.text`} />
						</Typography>
						<div>
						{category.services.map(service => 
							<Accordion
								expanded={expandedService === service.id} 
								onChange={handleChange(service.id)}
								className={classes.serviceAccordion}
								id={service.id}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
								>
									<FormControlLabel
										onClick={(event) => event.stopPropagation()}
										onFocus={(event) => event.stopPropagation()}
										onChange={(e) => {
											e.stopPropagation();
											handleCheck(service.id)
										}}
										control={
											<Switch
												checked={isChecked(service.id)}
												name="checkedB"
												color="primary"
											/>
										}
										label={<Fm 
											id={`popup.consentArea.${service.id}.title`} 
										/>}
									/>
								</AccordionSummary>
								<AccordionDetails
									style={{
										maxHeight: 230,
										overflowY: "scroll",
										display: "flex",
										flexDirection: "column"
									}}
								>
									<Typography>
										<Fm 
											values={{
												br: <br />
											}}
											id={`popup.consentArea.${service.id}.text`} 
										/>
									</Typography>
								</AccordionDetails>
							</Accordion>)}
						</div>
					</AccordionDetails>
				</Accordion>)}
					<Box
						className={classes.buttonBox}
					>
						<Button
							style={{marginRight: 8}}
							variant="outlined"
							color="secondary"
							size="small"
							/*  Does not work to track beforehand? -- for now postpone pre-cookie accept tracking
							GA: track user selected no, some or all cookies by clicking left button in cookie pop-up
							console.log(category.id); TODO get cookie category id and add to event {"selectedCookies" + category.id}
							*/
							onClick={() => {handleClose(),
							                ReactGA.event({category: "selectedCookies",
							                               action: "Accept Selected Cookies Button clicked",
							                               label: "User clicked Button in popup to accept selected choice"})
							                               }}>
							{/* onClick={handleClose}> */}
							<Fm id="popup.saveCustomSelectionButton.title" />
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							/* GA: track user accepted all cookies by click button in cookie pop-up */
							onClick={(e, reason) => {handleClose(e, reason, true),
							                         ReactGA.event({category: "allCookiesAccepted",
							                                        action: "Accept all Cookies Button clicked",
							                                        label: "User clicked Button in popup to accept all cookies"})
							                                        }}>
							{/* onClick={(e, reason) => handleClose(e, reason, true)}> */}
							<Fm id="popup.acceptAllButton.title" />
						</Button>
					</Box>
				</Box>
			</Paper>
		</Snackbar>
	)
}

export default withCookies(CookieConsent)
