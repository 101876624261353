import React, { useState, useEffect } from 'react'
import queryString from 'query-string';
import {withRouter} from "react-router-dom"
import {FormattedMessage as Fm} from "react-intl"
import cn from "classnames"
import * as selectors from "../redux/selectors"
import { connect } from "react-redux";
import {makeStyles} from "@material-ui/styles"
import styled from "styled-components";
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  ListItemIcon,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box
} from '@material-ui/core';
import {
  Star as StarIcon,
  CloudDone,
  LooksOne,
  LooksTwo,
  Looks3,
  Looks4,
  CheckCircle,
  Phone,
  Mail,
  Home
} from '@material-ui/icons';
import GovernanceIcon from "assets/01_Governance_icon_2c.svg"
import PlatformIcon from "assets/02_Platform_2c.svg"
import OperationsIcon from "assets/03_Operations_2c.svg"
import SecurityIcon from "assets/04_security_icon_2c.svg"
import ProtosLogo from "assets/PROTOS_Logo.svg"
import CALogo from 'assets/CLOUD_ADVISOR_with_Claim_white.svg';
import protosTeamPic from "assets/PROTOS-Technologie-team.jpg"
import microsoftPic from "assets/MSPartner_DataAnalytics.png"
import immersionPic from "assets/ImmersionDayPartner_AWSLambda.png"
const useStyles = makeStyles((theme) => ({
  certificateWrapper: {
    display: "flex",
    padding: theme.spacing(0,3),
    "& > div": {
      padding: theme.spacing(3),
      flex: 1,
      flexBasis: 160,
      "& > img": {
        width: "100%"
      }
    }
  },
  cta: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  root: {
    overflow: "visible",
    color: theme.palette.primary.contrastText,
  },
  headerWrapper: {
    "flexWrap": "wrap-reverse"
  },
  overviewCardItem: {
    [theme.breakpoints.down("sm")]: {
      flex: 1
    }
  },
  stepLabel: {
    "& .MuiSvgIcon-root": {
      fontSize: 28
    },
    "& .MuiStepLabel-label": {
      font: "normal normal bold 16px/21px Roboto"
    },
    "& .MuiStepContent-root": {
      font: "normal normal normal 16px/21px Roboto;"
    }
  },
  whiteColor: {
    color: theme.palette.primary.contrastText + "!important",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.contrastText
    }
  },
  dashboardCard: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF1A 0% 0% no-repeat padding-box",
  },
  dashboardHeader: {
    padding: theme.spacing(0, 3),
    fontTransform: "uppercase",
    height: 50,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      height: 70
    },
    color: "#01A2DD",
    borderBottom: "solid #1212121A",
    "& .MuiCardHeader-title": {
      fontSize: "1.25rem",
    }
  },
  dashboardContent: {
    padding: 0,
    color: theme.palette.primary.contrastText
  },
  dashboardIcon: {
    marginRight: 20, 
    height: 70, 
    width: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "110%",
    }
  },
  cloudIcon: {
    "&.red": {
      color: theme.palette.error.main,
    },
    "&.yellow": {
      color: theme.palette.warning.main,
    },
    "&.green": {
      color: theme.palette.success.main,
    }
  },
  dashboardListItem: {
    padding: theme.spacing(1, 3),
    minHeight: 75,
    borderBottom: "solid #1212121A",
    "&:last-child": {
      borderBottom: "initial"
    }
  },
  linearProgress: {
    height: 10,
    borderRadius: 5,
    background: "rgb(0 0 0 / 10%)",
  },

  rightIndicatorListItem: {
    "&.red": {
      borderRight: `solid ${theme.palette.error.main} 3px`,
    },
    "&.yellow": {
      borderRight: `solid ${theme.palette.warning.main} 3px`,
    },
    "&.green": {
      borderRight: `solid ${theme.palette.success.main} 3px`,
    }
  },
  colored: {
    "&.red": {
      background: theme.palette.error.main,
    },
    "&.yellow": {
      background: theme.palette.warning.main,
    },
    "&.green": {
      background: theme.palette.success.main,
    }
  },
  caLogoBox: {
    display: "flex", 
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start"
    }
  }
}));
const StyledLogo = styled(ProtosLogo)`
* {
  fill: white;
}
svg {
  max-width:100%;
}
width: 80%;
`
const mapRangeToColor = (range) => {
  if (range > 66) {
    return "green"
  } else if (range > 33) {
    return "yellow"
  } else if (range > 0) {
    return "red"
  }
}

const shortToLongCategoryId = {
  g: "governance",
  p: "platform",
  o: "operations",
  s: "security"
}

const categoryIdToIcon = {
  governance: <GovernanceIcon />,
  platform: <PlatformIcon />,
  operations: <OperationsIcon />,
  security: <SecurityIcon />
}
const stepIdToIcon = [
  <LooksOne />,
  <LooksTwo />,
  <Looks3 />,
  <Looks4 />,
]

const masculineCategories = ["operations"]
const animDuration = 1000

function ResultPage(props) {
  const classes = useStyles()
  const searchAttrs = queryString.parse(props.location.search);
  const resultCategories = searchAttrs ? Object.keys(searchAttrs).map(ak=>({ value: parseInt(searchAttrs[ak]), id: shortToLongCategoryId[ak] })) : []
  console.log(searchAttrs, resultCategories)
  const finalScore = resultCategories.reduce((a,c)=>a+c.value,0) / resultCategories.length
  const worstCategory = resultCategories.reduce((a,c) =>a.value > c.value ? c : a, resultCategories[0])
  // Dummy placeholder
  const [nextSteps, setNextSteps] = useState([0,1,2])
  return (
    <Grid container direction="column" alignItems="stretch" className={classes.root} spacing={3}>
      <Grid container item spacing={3} alignItems="center"
        data-aos="fade-up"
        data-aos-duration={animDuration}
        className={classes.headerWrapper}
      >
        <Grid xs={12} sm={6} item>
          <Typography>
            <Fm id="result.header.thankYouMessage.title" />
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
         <Box p={1} 
          className={classes.caLogoBox}>
          <CALogo
              viewBox="3 0 171 45"
              style={{
                maxWidth: "300px",
                width: "68%"
              }}
            />
         </Box>
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item sm={12} md={6} style={{flex: 1}}>
          <Card
            className={classes.dashboardCard}
            // gets height from right Side
            style={{height: "100%"}}
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-offset="-6000"
            data-aos-duration={animDuration}
          >
            <CardHeader
              className={classes.dashboardHeader}
              title={<Fm id="result.overviewCard.title" />}
            />
              <List
                className={classes.dashboardContent}
                style={{display: "flex", flexDirection: "column", flex: 1}}
              >
                {resultCategories.map(c=> <ListItem 
                  className={cn(classes.dashboardListItem)}
                  style={{border: "initial", flex: 1}}
                >
                  <ListItemText
                    primary={<Typography variant="button">
                      <Fm id={`question.category.${c.id}`} />
                    </Typography>}
                    secondary={<div
                      style={{paddingTop: 5}}
                    >
                        <LinearProgress 
                          classes={{
                            root: classes.linearProgress,
                            barColorPrimary: cn(mapRangeToColor(c.value), classes.colored),
                            barColorSecondary: classes.test
                          }}
                          // value={c.range} 
                          value={c.value}
                          variant="determinate"
                        />
                    </div>}
                  />
                </ListItem>)}
              </List>
          </Card>
        </Grid>
        <Grid item sm={12} md={6} style={{flex: 1}} >
          <Card
            className={classes.dashboardCard}
            data-aos="fade-up" 
            data-aos-delay="400"
            data-aos-offset="-6000"
            data-aos-duration={animDuration}
          >
            <CardHeader
              className={classes.dashboardHeader}
              title={<Fm id="result.categoriesCard.title" />}
            />
              <List 
                className={classes.dashboardContent}
              >
                {resultCategories.map(c => <ListItem
                  className={cn(classes.dashboardListItem, classes.rightIndicatorListItem, mapRangeToColor(c.value))}
                >
                  <ListItemIcon
                    className={classes.dashboardIcon}
                  >
                    {categoryIdToIcon[c.id]}
                  </ListItemIcon>
                  <ListItemText
                    secondaryTypographyProps={{
                      color: "inherit"
                    }}
                    primary={<Fm id={`question.category.${c.id}`} />}
                    secondary={<Fm id={`result.categoriesCard.category.${c.id}.text`} />}
                  />
                </ListItem>)}
              </List>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card
          elevation={20}
          data-aos="fade-up" 
          data-aos-delay="600"
          data-aos-offset="-6000"
          data-aos-duration={animDuration}
        >
            <CardHeader
              className={classes.dashboardHeader}
              title={<Fm id="result.scoreCard.title" />}
            />
            <Box p={3}>
              <Grid container direction="row" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Typography>
                    {worstCategory && <Fm 
                      id="result.scoreCard.text" 
                      values={{
                        anrede: masculineCategories.indexOf(worstCategory.id) > -1 ? "Ihr" : "Ihre",
                        worstPerformer: <Fm id={`question.category.${worstCategory.id}`} />
                      }}
                    />}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="button">
                    Cloud Readiness
                  </Typography>
                  <CloudDone 
                    className={cn(mapRangeToColor(finalScore), classes.cloudIcon)}
                    style={{
                      height: "100%",
                      marginLeft: 10,
                      fontSize: 60,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
      </Grid>
      <Grid item container spacing={3} justify="stretch" alignItems="stretch">
        <Grid className={classes.overviewCardItem} item sm={12} md={6}>
          <Card
            className={classes.dashboardCard}
            data-aos="fade-up" 
            data-aos-delay="800"
            data-aos-offset="-6000"
            data-aos-duration={animDuration}
          >
            <CardHeader
              className={classes.dashboardHeader}
              title={<Fm id="result.nextStepsCard.title" />}
            />
            <List
              className={classes.dashboardContent}
            >
              <ListItem className={classes.dashboardListItem}>
                <Typography>
                  <Fm id="result.nextStepsCard.text" />
                </Typography>
              </ListItem>
              <ListItem
                className={classes.dashboardListItem}
                style={{flexDirection: "column", alignItems: "flex-start"}}
              >
                <Stepper
                  orientation="vertical"
                  style={{background: "transparent", color: "white", padding: 0}}
                >
                  {nextSteps.map((s,i) => <Step
                    active={true}
                  >
                    <StepLabel
                      StepIconComponent={()=>stepIdToIcon[i]}
                      style={{color: "inherit"}}
                      classes= {{
                        root: classes.stepLabel,
                        label: classes.whiteColor,
                      }}
                    >
                      <Fm id={`result.nextStepsCard.step.${i}.title`} />
                    </StepLabel>
                    <StepContent>
                      <Fm id={`result.nextStepsCard.step.${i}.text`} />
                    </StepContent>
                  </Step>)}
                </Stepper>
              </ListItem>
              <ListItem className={classes.dashboardListItem}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.cta}
                  endIcon={<CheckCircle />}
                  onClick={() => window.open("https://meetings.hubspot.com/cloudadvisorkalender","_blank")}
                >
                  <Fm id="result.nextStepsCard.cta" />
                </Button>
              </ListItem>
            </List>
          </Card> 
        </Grid>
        <Grid className={classes.overviewCardItem} item sm={12} md={6}>
          <Card
            className={classes.dashboardCard}
            style={{height: "100%"}}
            data-aos="fade-up" 
            data-aos-delay="1000"
            data-aos-offset="-6000"
            data-aos-duration={animDuration}
          >
            <CardHeader
              className={classes.dashboardHeader}
              title={<Fm 
                id="result.endCard.title" 
                />}
            />
            <List
              className={classes.dashboardContent}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ListItem 
              className={classes.dashboardListItem}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: 0,
                paddingBottom: 8
              }}
              >
                <img 
                style={{width: "100%", marginBottom: 8}}
                src={protosTeamPic} />
                <Typography
                  style={{
                    paddingRight: 24,
                    paddingLeft: 24
                  }} 
                >
                  <Fm 
                    id="result.endCard.text" 
                    values={{
                      br: <br />
                    }}
                  />
                </Typography>
              </ListItem>
              <ListItem
                className={classes.dashboardListItem}
                style={{flexDirection: "column", alignItems: "flex-start"}}
              >
                <List className={classes.whiteColor} 
                >
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                    <ListItemText>
                      +49-30-959998170
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <Mail />
                    </ListItemIcon>
                    <ListItemText>
                      info@protos-tec.de
                    </ListItemText>
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <Home  />
                    </ListItemIcon>
                    <ListItemText>
                      www.protos-technologie.de
                    </ListItemText>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem
                className={cn(classes.dashboardListItem, classes.certificateWrapper)}
              >
                <div>
                  <img src={microsoftPic} />
                </div>
                <div>
                  <img src={immersionPic} />
                </div>
              </ListItem>
            </List>
          </Card> 
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(ResultPage)