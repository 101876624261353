import React from 'react'
import {FormattedMessage as Fm} from "react-intl";
import './../App.css'
import Button from '@material-ui/core/Button';
import {
  Typography,
  Divider,
} from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import styled from "styled-components";
import * as constants from "../constants"
import {useIsMobile} from "./../hooks"
import { makeStyles } from '@material-ui/core/styles';

const Link = styled(MuiLink)`
    text-decoration: none;
    color: white;
`
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },
  date: {
    font: "normal normal normal 10px/9px Roboto",
    letterSpacing: "0.01px"
  },
  impressum : {
    width: "115px",
    font: "normal normal normal 10px/9px Roboto",
    letterSpacing: "0.01px",
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 6
    },
    display: "flex",
    justifyContent: "space-between",
    '& > hr': {
      background: "white",
      height: "initial"
    },
  }
}));
const Wrapper = styled.div`
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around;
  min-height: 30px;
  padding: 10px 20px;
  padding-bottom: 10px;
`
function Footer(props) {
  const classes = useStyles();

  return (
    <Wrapper
      className={classes.mainWrapper}
    >
        <Typography className={classes.date} variant="body1">©{" "}2021 PROTOS Technologie GmbH</Typography>
        <div
          className={classes.impressum}
        >
          <Link href="https://www.protos-technologie.de/impressum/" target="_blank" rel="noopener noreferrer">
            <Fm id="footer.impressum.title" />
            </Link>
          <Divider orientation="vertical"/>
          <Link href="https://www.protos-technologie.de/datenschutz/" target="_blank" rel="noopener noreferrer">
            <Fm id="footer.dataPrivacy.title" />
          </Link>
        </div>
    </Wrapper>
  )
}

export default Footer
