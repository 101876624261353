import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { ReactComponent as BaseImg } from "./base.svg";

// DO NOT CHANGE
const TILE_CONFIG = [
  // Tile 1
  {
    transform: "scale(2.7) translate(13%, 18%)",
  },

  // Tile 2
  {
    transform: "scale(2.7) translate(-30%, -10%)",
  },

  // Tile 3
  {
    transform: "scale(3.2) translate(25%, 0%)",
  },

  // Tile 4
  {
    transform: "scale(3.2) translate(-4%, -22%)",
  },

  // Tile 5
  {
    transform: "scale(2.7) translate(30%,-25%)",
  },
];

const ImageComponent = ({ questions, activeTile }) => {
  const outerDiv = useRef(null);
  const mergedDiv = useRef(null);
  const mergedSvg = useRef(null);
  const baseDiv = useRef(null);
  const overlayDiv = useRef(null);
  const overlayDivs = useRef(null);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let overlays = questions
      .map((q) => {
        return q.answers.map((a) => null);
      })
      .flat();

    overlayDivs.current = overlays;
    
    let answers = questions
      .map((q) => {
        return q.answers.map((a) => ({...a, answered: q.answeredId === a.id}));
      })
      .flat();
    setAnswers(answers);
  }, [questions]);

  useEffect(() => {
    // Get base content
    let _baseDiv = baseDiv.current;
    let baseSvg = _baseDiv.getElementsByTagName("svg")[0];
    let baseContent = Array.from(baseSvg.childNodes);

    baseContent.forEach((node) => {
      node.style.position = "absolute";
    });

    // Get overlay content
    let overlayContent = [];

    answers.forEach((a, i) => {
      if (a && a.svg && a.answered) {
        let _overlayDiv = overlayDivs.current[i];
        let overlaySvg = _overlayDiv.getElementsByTagName("svg")[0];

        overlaySvg.setAttribute("width", `${140 * a.size}`);
        overlaySvg.setAttribute("height", `${140 * a.size}`);
        overlaySvg.setAttribute("x", a.posX);
        // viewbox height - posY - element height
        overlaySvg.setAttribute("y", `${2050 - Number(a.posY) - 100}`);

        overlayContent = [...overlayContent, overlaySvg];
      }
    });

    const merged = mergedDiv.current;
    const _mergedSvg = mergedSvg.current;

    // Keep viewbox of baseSvg
    _mergedSvg.setAttribute("viewBox", baseSvg.getAttribute("viewBox"));
    merged.appendChild(_mergedSvg);

    // Merging content
    for (let i = 0; i < baseContent.length; i++) {
      _mergedSvg.appendChild(baseContent[i]);
    }
    for (let i = 0; i < overlayContent.length; i++) {
      _mergedSvg.appendChild(overlayContent[i]);
    }

    // remove the initial divs
    _baseDiv.remove();
    overlayDiv.current.remove();
  }, [answers]);

  useEffect(() => {
    let _mergedSvg = mergedDiv.current;
    _mergedSvg.style.transition = `transform 500ms`;

    for (let i = 0; i < TILE_CONFIG.length; i++) {
      const { transform } = TILE_CONFIG[i];

      if (activeTile === i + 1) {
        _mergedSvg.style.transform = transform;
        return;
      }
    }

    _mergedSvg.style.transform = `scale(1) translate(0%,0%)`;
  }, [activeTile]);

  return (
    <>
      <div id="outer" ref={outerDiv} style={{ position: "relative", overflow: "hidden" }}>
        <div id="base-div" ref={baseDiv}>
          <BaseImg width="100%" id="base" />
        </div>
        <div id="overlay-div" ref={overlayDiv}>
          {answers.map((a, i) => {
            let inner = a.svg;
            return a.svg ? (
              <div
                ref={(el) => (overlayDivs.current[i] = el)}
                id={"overlay-" + a.id}
                key={"overlay-" + a.id}
                style={{opacity: a.answered ? 1 : 0}}
                dangerouslySetInnerHTML={{ __html: inner }}
              />
            ) : null;
          })}
        </div>
        <div className="merged-div" ref={mergedDiv}>
          <svg id="merged" ref={mergedSvg} />
        </div>
        <div
          className="invisible-overlay"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 2,
            backgroundColor: "transparent",
          }}
        />
      </div>
    </>
  );
};

ImageComponent.propTypes = {
  activeTile: PropTypes.number,
  questions: PropTypes.array.isRequired,
};

export default ImageComponent;
