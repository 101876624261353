export const DE = "DE"
export const EN = "EN"


export const SKIP_PLACEHOLDER = "__SKIP"


// COOKIE
export const FUNC_CATEGORY = "func"
export const YOUTUBE_ID = "youtube"
export const GA_ID = "ga"
export const COOKIE_CHECKED_SERVICES_NAME = "checkedServices"