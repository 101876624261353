import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Provider, connect } from "react-redux";
import store from "./redux/store";
import protosTheme from "./theme"
import {IntlProvider} from 'react-intl'
import translations from "./translations.json";
import { ThemeProvider , createMuiTheme } from '@material-ui/core/styles'
import {
  BrowserRouter as Router,
} from "react-router-dom";

const ConnectedIntlProvider = connect((state) => {
  const { language, questions } = state;
  const messages = {
    ...translations[language],
  }
  questions.forEach(q=>{
    const lanKey = language.toLowerCase()
    messages[`question.${q.id}.title`] = q.title[lanKey]
    if (q.explanation) {
      messages[`question.${q.id}.explanation`] = q.explanation[lanKey]
    }
    q.answers.forEach(a=>{
      messages[`question.${q.id}.answer.${a.id}.title`] = a.title[lanKey]
    })
  })
  return { locale: language, messages };

})(IntlProvider);
ReactDOM.render(<Provider store={store}>
  <ConnectedIntlProvider>
      <ThemeProvider theme={protosTheme}>
        <Router>
          <App />
        </Router>
    </ThemeProvider >
  </ConnectedIntlProvider>
  </Provider>, document.getElementById('root'))
