import React, { useState, useEffect } from 'react'
import {FormattedMessage as Fm} from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import CloudAdvisorLogo from "assets/CLOUD_ADVISOR_landingpage.svg"
import ReactGA from 'react-ga';
import {
  Link,
  useHistory 
} from "react-router-dom";
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Hidden,
  Box
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  CheckCircle
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  button: {
    width: "250px",
    [theme.breakpoints.down('sm')]: {
      width: "300px"
    },
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    textTransform: "uppercase",
  },
  mainWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      marginTop: "5%"
    },
  },
  heroText: {
    font: "normal normal normal 42px/48px Open Sans",
    marginBottom: "25px"
  },
  secondaryHeroText: {
    textAlign: "left",
    font: "normal normal normal 24px/31px Open Sans",
    letterSpacing: "0.12px",
    marginBottom: "25px"
  }
}));
const MainWrapper = styled.div`
max-height: 100%;
color: white;
height: 100%;
display: flex;
flex-direction: column;
`

const OverlayContainer = styled.div`
pointer-events:none; overflow:hidden; 
width:0 !important; 
height:0 !important; 
`
const FmBr = (props) => <Fm             
  values={{
    br: <br />
  }} 
  {...props}  
/>

function LandingPage(props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (!isSmall) {
      initAnimation()
    }
  })

  const AnimationContainer = styled.div`
      opacity: ${isSmall ? 0 : 1};
      display: flex;
      justify-content: flex-end;
      align-items: center;
  `
  const Canvas = styled.canvas`
    width: ${isSmall ? 0 : "480px"} !important;
    height: ${isSmall ? 0 : "480px"} !important;
  `

  const duration = 2000
  return (
    <MainWrapper
      className={classes.mainWrapper}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Grid 
          item
          md={6}
          xs={12}
        >
          <Box
            px={0}
            data-aos="fade-up" 
            data-aos-offset="-10000"
            data-aos-duration={duration}
          >
            <CloudAdvisorLogo
              viewBox="0 0 170 30"
              style={{
                width: "300px",
                marginLeft: "-6px",
              }}
            />
            <Typography 
              className={classes.heroText}
              data-aos="fade-up" 
              data-aos-duration={duration}
            >
              <FmBr 
                id="landing.hero.title" 
              />
            </Typography>
            <Typography
              className={classes.secondaryHeroText}
              data-aos-delay="300"
              data-aos-offset="-10000"
              data-aos="fade-up" 
              data-aos-duration={duration}
            >
              <FmBr id="landing.hero.subTitle" />
            </Typography>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              endIcon={<CheckCircle />}
              // GA: track user clicked start CloudAdvisor button
              onClick={() => {history.push("/main"); ReactGA.event({category: "startButton",action: "startButton click",label: "User clicked start button on landing page"})}}
              data-aos-delay="600"
              data-aos-offset="-10000"
              data-aos="fade-up" 
              data-aos-duration={duration}
            >
              <Fm id="landing.hero.cta" />
            </Button>
          </Box>
        </Grid>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AnimationContainer id="animation_container" data-aos="zoom-in" data-aos="zoom-in" data-aos-duration={duration}>
              <Canvas id="canvas" width="100%"  height="100%"></Canvas>
              <OverlayContainer id="dom_overlay_container">
              </OverlayContainer>
            </AnimationContainer>
          </Grid>
        </Grid>
    </MainWrapper>
  )
}

export default LandingPage;
