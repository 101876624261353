import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      light: '#0179B7',
      main: '#01A2DD',
      dark: '#005495',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0179B7',
      light: '#fff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#01A2DD',
    },
    warning: {
      main: "#FABF3A"
    },
    success: {
      main: "#11CC7D"
    },
    error: {
      main: "#E34A4A"
    }
  },
  typography: {
    htmlFontSize: 16,
  }
});

export default theme;


// backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`
// // im <head> der index.html platzieren
// <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet"></link>