import React, { useEffect, useState, useRef } from 'react';
import {FormattedMessage as Fm} from "react-intl"
import styled from "styled-components";
import cn from "classnames"
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import {
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Checkbox,
    FormHelperText,
    Button,
    Box
} from '@material-ui/core';
import {
    Done, PinDropSharp
} from '@material-ui/icons';

import {translate} from "../../helpers";
import {useIsMobile} from "../../hooks"
import * as constants from "../../constants"
import { setFinished } from "../../redux/actions";
import * as selectors from "../../redux/selectors";

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`
const useStyles = makeStyles((theme) => ({
    formLabel: {
        marginTop: theme.spacing(2)
    },
    checkBoxText: {
        marginLeft: theme.spacing(4)
    },
    formInput: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "49%",
        }
    },
  }));
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
function EmailForm({lan, setChosenAnswer,isValid, chosenAnswer, setFinished, ...props}) {
    const classes = useStyles();
    const [email, setEmail] = useState(chosenAnswer.email_to);
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState(chosenAnswer.name);
    const [lastName, setLastName] = useState(chosenAnswer.lastName);
    const [yesUpdates, setYesUpdates] = useState(chosenAnswer.yesUpdates);
    const [yesSaving, setYesSaving] = useState(chosenAnswer.yesSaving);
    const onChangeEmail = (value) => {
        setEmail(value)
        if (value.length > 5 && value.match(emailRegex)) {
            setEmailError("")
        } else {
            setEmailError(<Fm id="emailForm.email.error" />)
        };
        // GA: track user put in Email in contact formular
        ReactGA.event({category: "putEmailinFormular",action: "input Email in ContactFormular",label: "User wrote in Email field in contact formular at the end"})
    }
    useEffect(() => {
        // GA: virtual page view to track contact form
        ReactGA.pageview("/contactform");
        const validEmail = emailError.length === 0 ? email : undefined
        setChosenAnswer({
            lan,
            email_to: validEmail,
            name,
            lastName,
            yesSaving,
            yesUpdates,
        })
    }, [email, name, lastName, yesSaving, yesUpdates])

    const isMobile = useIsMobile();
    return (<Wrapper>
        <TextField 
            label={<Fm id="emailForm.name" />}
            value={name} 
            className={cn(classes.formInput, classes.formLabel)}
            // GA: track user put in name in contact formular
            onChange={(e) => {setName(e.target.value); ReactGA.event({category: "putNameinFormular",action: "input Name in ContactFormular",label: "User wrote in name field in contact formular at the end"})}}
        />
        <TextField 
            label={<Fm id="emailForm.lastName" />}
            value={lastName} 
            className={cn(classes.formInput, classes.formLabel)}
            // GA: track user put in lastname in contact formular
            onChange={(e) => {setLastName(e.target.value); ReactGA.event({category: "putLastNameinFormular",action: "input LastName in ContactFormular",label: "User wrote in LastName field in contact formular at the end"})}}
        />
        <TextField 
            error={emailError.length === 0 ? false : true}
            helperText={emailError}
            label={<Fm id="emailForm.email" />}
            value={email} 
            className={cn(classes.formLabel)}
            fullWidth
            onChange={(e) => onChangeEmail(e.target.value)}
        />
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel 
                    className={classes.formLabel}
                    // GA: track user checked Update box in contact formular
                    control={<Checkbox checked={yesUpdates} onChange={(e) => {setYesUpdates(!yesUpdates); ReactGA.event({category: "checkUpdateinFormular",action: "check box for Updates in ContactFormular",label: "User checked the Checkbox for receiving future updates in contact formular"})}} name="yesUpdates" />}
                    label={<Fm id="emailForm.notifications.title" />}
                />
                <FormHelperText
                    className={classes.checkBoxText}
                >
                    <Fm id="emailForm.notifications.helper" />
                </FormHelperText>
                <FormControlLabel 
                    className={classes.formLabel}
                    // GA: track user checked Save Data box in contact formular
                    control={<Checkbox checked={yesSaving} onChange={(e) => {setYesSaving(!yesSaving); ReactGA.event({category: "checkSaveDatainFormular",action: "check box for Saving Data in ContactFormular",label: "User checked the Checkbox for saving data in contact formular"})}} name="yesSaving" />}
                    label={<Fm id="emailForm.dataSaving.title" />}
                />
                <FormHelperText
                    className={classes.checkBoxText}
                >
                    <Fm id="emailForm.dataSaving.helper" />
                </FormHelperText>
            </FormGroup>
        </FormControl>
    </Wrapper>
    )
}
export default connect(
    (state)=>({
        lan: selectors.getCurrentLanguage(state),
    }),
    { setFinished }
  )(EmailForm);
  