import React, { useEffect, useState, useRef } from 'react';
import {FormattedMessage as Fm} from "react-intl";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { sendToS3 } from '../../remote';
import { connect } from "react-redux";
import * as selectors from "redux/selectors"
import ImageComponent from './ImageComponent';
import styled from "styled-components";
import {useIsMobile} from "./../../hooks"
import EmailBackground from "assets/Background_CA-Results.png"
// REMOTE
import axios from "axios";
import ReactGA from 'react-ga';

// REDUX
import { prevQuestion, skipQuestion, nextQuestion, finish } from "../../redux/actions";

// COMP
import Question from './Question'
import Navigation from "./Navigation"
import Akkordeon from "./Akkordeon"
// STYLE
import './../../App.css'
import { 
  Typography,
  Card,
  CardHeader, 
  CardActions,
  CardContent,
  MenuItem,
  Button,
  CircularProgress,
  Box,
  useTheme
} from '@material-ui/core';
import {
  NavigateBefore,
  PinDropSharp,
  NavigateNext,
  CheckCircle
} from '@material-ui/icons';

import cn from "classnames";

const QuestionRowWrapper = styled.div`
flex: 1; 
padding-top: 25px;
display: flex;
min-height: 0;
`
const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    alignItems: "center",
    position: "relative",
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    [theme.breakpoints.up('sm')]: {
      // width:800,
    }
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  cardWrapper: {
    margin: theme.spacing(2, 0),
    borderRadius: theme.spacing(1),
    padding: 0,
    zIndex: 5,
  },
  cardActions: {
    padding: theme.spacing(2),
  },
  emailBkg: {
    position: "absolute",
    marginTop: 70,
    filter: "blur(5px)",
    width: "110%"
  }
}));


export const getHasAnswer = (qs) => {
  if (qs.length) {
    const type = qs[0].type.toLowerCase()
    const isExempt = ["info", "video", "text"].includes(type)
    if (isExempt) {
      return true
    }
    if (type === "multipleselect") {
      return qs[0].answeredId && Object.values(qs[0].answeredId).some(v=>Boolean(v))
    }
    if (type === "email") {
      const leaderAnswers = qs[0].answeredId
      const {yesSaving, email_to, name, lastName} = leaderAnswers
      return yesSaving && email_to && name && lastName
    }
    return qs.every(q=>q.answeredId)
  }
  return false
}

function Content({currentQuestions, finished, ...props}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme()
  const [remoteLoading, setRemoteLoading] = useState(false);
  const isAkkordeon = currentQuestions.length > 1;
  const leader = currentQuestions[0] || {}
  // Take both from the leading question
  const activeCategory = <Fm id={`question.category.${leader.categoryId}`} defaultMessage="Cloud Adoption" /> 
  const skipId = leader.skipId
  const type = leader.type ? leader.type.toLowerCase() : ""
  const isEmail = type=== "email"
  const hasAnswer = getHasAnswer(currentQuestions, type)
  const messagesEndRef = useRef(null)

  console.log(currentQuestions)



  const submitRemote = async ()=> {
    if (!remoteLoading && !finished)
      setRemoteLoading(true);
      console.log(leader.answeredId,props.allQuestions)
      // GA: track user sent contact formular
      ReactGA.event({category: "sentContactFormular",action: "nextButton on ContactFormular click (=sent)",label: "User clicked nextButton on contact formular at the end"})
      axios({
          header: {
              'Content-Type': 'application/json'
          },
          method: "POST",
          url: 'https://uvwhwtddw6.execute-api.eu-central-1.amazonaws.com/dev/CloudadvisorMailing',
          data: {
            ...leader.answeredId,
            questions: props.allQuestions
          }
      })
      .catch(function (error) {
          setRemoteLoading(false);
          console.log(error)
      })
      .then(function (response) {
        setRemoteLoading(false);
        if (response && response.data && isNaN(response.data)) {
          const queryString = response.data.split("?")[1]
          history.push("/result?"+ queryString)
          // GA: track user reached result page
          ReactGA.event({category: "reachedResults",action: "landed on Result page",label: "User reached Results page at the end"})
        } else {
          console.log("Reached lower branch routing to thank you page")
          history.push("/thankyou")
          // GA: track user reached thank you page
          ReactGA.event({category: "reachedThankYou",action: "landed on Thank you page",label: "User reached Thank you page at the end"})
        }
    })
  }

  const handleNext = () => {
    if (isEmail) {
      // GA: track users reaching contact formular
      // ReactGA.event({category: "reachedContactFormular",action: "landed on ContactFormular",label: "User reached contact formular at the end"})
      submitRemote()
    } else {
      props.nextQuestion()
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollTop -= 10;
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
  }
  useEffect(() => {
    if (hasAnswer) {
      scrollToBottom()
    }
  }, [hasAnswer])

  return (
    <div
      ref={messagesEndRef}
      className={classes.contentWrapper}
      style={{alignItems: isEmail ? "center" : "stretch"}}
    >
        <Navigation 
          progress={props.progress}
        />
        {isEmail && <img 
          data-aos="fade-up"
          data-aos-duration={1000}
          className={classes.emailBkg}
          src={EmailBackground} 
        />}
        <Card
          className={classes.cardWrapper}
          elevation={isEmail ? 15 : 2}
          style={{
            maxWidth: isEmail ? 500 : "inherit",
            marginTop: isEmail ? 80 : theme.spacing(3)
          }}
        >
            <CardHeader
              className={classes.header}

              title={<Typography variant="h6" style={{textTransform: "uppercase"}}>
                  {activeCategory}
              </Typography>}
            />
            <CardContent
              style={{
                flex: 1,
                padding: 0 
              }}
            >
              {leader.id && (isAkkordeon ? <Akkordeon
                questions={currentQuestions}
              /> :
              <Question
                {...currentQuestions[0]}
              />)}
            </CardContent>
            <CardActions
              className={classes.cardActions}
            >
                <Button size="small"
                    disabled={props.isFirst}
                    //onClick={props.prevQuestion}
                    onClick={() => {props.prevQuestion(); ReactGA.event({category: "previousButton",action: "previousButton click",label: "User went back to prev. Question"})}}
                >
                  <NavigateBefore />
                  <Fm id="question.prevButton.title" />
                </Button>
                <div style={{flex: 1}} />
                {skipId && <Button 
                    //onClick={props.skipQuestion}
                    onClick={() => {props.skipQuestion(); ReactGA.event({category: "skipButton",action: "skipButton click",label: "User skipped Question"})}}

                >
                    <Fm id="question.skipButton.title" />
                </Button>}
                <Button 
                    endIcon={<CheckCircle />}
                    color="primary"
                    variant="outlined"
                    disabled={!hasAnswer}
                    onClick={() => {handleNext();
                                    //console.log(currentQuestions[0].id);
                                    ReactGA.event({category: "nextButton"+currentQuestions[0].id ,action: "nextButton click",label: "User progress"})}}
                >
                  {remoteLoading && <Box mr={2}>
                    <CircularProgress 
                    size={25}
                    />
                  </Box>}
                  <Fm id="question.nextButton.title" />
                  <NavigateNext />
                </Button>
            </CardActions>
        </Card>
    </div>
  )
}

export default connect(
  (state) => ({ 
    progress: selectors.getProgress(state),
    finished: state.finished,
    isFirst: selectors.getIsFirst(state),
    currentQuestions: selectors.getCurrentQuestions(state),
    answeredQuestions: selectors.getAnsweredQuestions(state),
    allQuestions: selectors.getAllQuestions(state)
  }),
  { prevQuestion, skipQuestion, nextQuestion, finish }
)(Content);

