import React, { useEffect, useState, useRef } from 'react';
import {FormattedMessage as Fm} from "react-intl";
import { 
    Typography,
    Card,
    CardHeader, 
    CardActions,
    CardContent,
    MenuItem,
    Button,
    CircularProgress,
    Box
  } from '@material-ui/core';
  import {
    NavigateBefore,
    PinDropSharp,
    NavigateNext,
    CheckCircle
  } from '@material-ui/icons';
function CircularProgressWithLabel({progressPercent, classes}) {
    return  <Box style={{height:"90px"}} position="relative" display="inline-flex">
    <CircularProgress 
    size={90}
    className={classes.circle}
    variant="determinate"
    value={progressPercent}
    />
    <CircularProgress 
    className={classes.circle}
    style={{
      opacity: 0.3,
      zIndex: 2
    }}
    size={90}
    variant="determinate"
    value={100}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="90px"
      width="90px"
    >
      <Typography variant="caption" component="div" 
        className={classes.progressText}
      >{`${Math.round(
        progressPercent,
      )}%`}</Typography>
    </Box>
  </Box>
  
  }
  export default CircularProgressWithLabel