import React, { useState, useEffect } from 'react'
import {FormattedMessage as Fm,useIntl} from "react-intl";
import { connect } from "react-redux";
import { answerQuestion, nextQuestion } from "../../redux/actions";
import * as selectors from "../../redux/selectors";
import * as constants from "../../constants"
import EmailForm from "./EmailForm";
import styled from "styled-components";
import {useIsMobile} from "../../hooks"
import YouTube from 'react-youtube';
import { withCookies } from 'react-cookie';
import {YOUTUBE_ID, COOKIE_CHECKED_SERVICES_NAME} from "constants"
// STYLE
import { 
    Typography,
    TextField,
    Slider, 
    Select,
    Radio,
    FormControlLabel,
    MenuItem,
    RadioGroup,
    FormGroup,
    Checkbox,
    FormControl,
    FormHelperText,
    Box,
    Button
  } from '@material-ui/core';
import {makeStyles} from "@material-ui/styles"

const SKIP_PLACEHOLDER = "__"
const useStyles = makeStyles(theme=>({
    root: {
        width: "100%",
        padding: "0 " + theme.spacing(2)
    },
    ytPlayer: {
        "&#player": {
            width: "100%"
        }
    }
}))


function QuestionContentComponent({ 
    id,
    type: qType, 
    answers, 
    answeredId,
    isAnswered,
    explanation,
    isAkkordeonElement,
    cookies,
    ...props
}) {
    const intl = useIntl()
    const classes = useStyles()
    const type = qType.toLowerCase()
    const checkedServices = cookies.get('checkedServices')
    const [chosenAnswer, setChosenAnswer] = useState(answeredId);

    const renderExplanation = ()=> <FormHelperText variant="body2" color="textSecondary" component="p" style={{padding: "10px 0"}}>
        {explanation && <Fm 
            id={`question.${id}.explanation`} 
            defaultMessage=" "
        /> }
    </FormHelperText>
    useEffect(() => {
        if (type === "multipleselect") {
            const skeletonObj = answers.reduce((acc, cur) => ({ ...acc, [cur.id]: false }), {})
            const initObj = typeof answeredId === "object" ? {...skeletonObj, ...answeredId} : skeletonObj
            setChosenAnswer(initObj)
        }
        else {
            setChosenAnswer(answeredId)
        }
    }, [id])
    useEffect(() => {
        if (chosenAnswer !== answeredId) {
            let newAnswer = chosenAnswer

            props.answerQuestion(id, newAnswer)
            if (isAkkordeonElement)
                props.nextQuestion(id)
        }
    }, [chosenAnswer])

    const handleCookieEnable = () => {
        const newCheckedServices = [...checkedServices, YOUTUBE_ID]
        console.log(newCheckedServices, checkedServices)
        cookies.set(COOKIE_CHECKED_SERVICES_NAME, JSON.stringify(newCheckedServices))
    }
    switch(type.toLowerCase()) {
        case "binary": {
            return <FormControl 
            style={{width: "100%"}}
            component="fieldset">
                <RadioGroup value={chosenAnswer || ""} onChange={(e) => setChosenAnswer(e.target.value)}>
                    {answers.map((answer) => <FormControlLabel 
                    key={answer.id}
                    control={<Radio color="primary" />}
                    label={<Fm id={`question.${id}.answer.${answer.id}.title`} />}
                    value={answer.id} />)}
                </RadioGroup>
                {renderExplanation()}
            </FormControl>
        } break;
        case "multiple": {
            return  <FormControl
            style={{width: "100%"}}
            >
            <Select
                labelId="demo-simple-select-label"
                value={chosenAnswer}
                onChange={(e) => setChosenAnswer(e.target.value)}
            >
                {answers.map((answer) => <MenuItem key={answer.id} value={answer.id}><Fm id={`question.${id}.answer.${answer.id}.title`} /></MenuItem>)}
            </Select>
            {renderExplanation()}
            </FormControl>
        } break;
        case "multipleselect": {
            return  <FormControl
            style={{width: "100%"}}
            >
                <FormGroup>
                    {answers.map(answer=> <FormControlLabel
                        key={answer.id}
                        control={<Checkbox checked={Boolean(chosenAnswer[answer.id])} 
                            onChange={(e) => setChosenAnswer({...chosenAnswer, [answer.id]: !Boolean(chosenAnswer[answer.id])})} 
                            name={answer.id} 
                        />}
                        label={<Fm id={`question.${id}.answer.${answer.id}.title`} />}
                    />)}
                </FormGroup>
                {renderExplanation()}
            </FormControl>
        }
        case "slider": {
            return <Slider
                style={{width: "100%"}}
                onChange={(e, v) => setChosenAnswer(v)}
                value={chosenAnswer || 50}
                valueLabelDisplay="auto"
                step={5}
                marks
                min={0}
                max={100}
            />
        } break;
        case "text": {
            return  <FormControl
            style={{width: "100%"}}
            >   
                <TextField 
                value={typeof chosenAnswer === "string" ? chosenAnswer : ""}
                multiline 
                rows={3} 
                fullWidth 
                id="standard-basic" 
                onChange={(e) => setChosenAnswer(e.target.value)} />
                {renderExplanation()}
            </FormControl>
        } break;
        case "email": {
            return <EmailForm 
                chosenAnswer={chosenAnswer}
                setChosenAnswer={setChosenAnswer}
            />
        }
        case "info": {
            return renderExplanation()
        }
        case "video": {
            return checkedServices.includes(YOUTUBE_ID) ? <FormControl
            style={{width: "100%"}}
            >   
                <YouTube
                    className={classes.ytPlayer}
                    style={{width:"100%"}}
                    videoId={intl.locale === "DE" ? "UiEQKGWDSXo" : "0CiQrQBCoeo"}
                    id={"player"}                   
                    opts={{
                        playerVars: {
                            width: "100%",
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 1,
                        },
                    }}
                    // onEnd={func}                      
                />
                {renderExplanation()}
            </FormControl> 
            : <Box
                    p={3}
                    style={{display: "flex", flexDirection: "column"}}
            >
                <Typography variant="h4">You disabled Youtube Cookies!</Typography>
                <Button
                    onClick={handleCookieEnable}
                    variant="contained"
                    color="primary"
                >
                    Enable Youtube cookies to watch video
                </Button>
            </Box>
        }
        default: 
            return <div>Error</div>
    }
}

function Question({ 
    type, 
    answers, 
    title, 
    id, 
    explanation, 
    answeredId, 
    ...props 
}) {
    const classes = useStyles()

    return <Box
        px={3}
        // className={classes.root}
    >
        <Typography variant="h5" gutterBottom>
            <Fm id={`question.${id}.title`} />
        </Typography>
        <QuestionContent 
            id={id}
            type={type}
            answers={answers} 
            answeredId={answeredId}
            explanation={explanation}
        />
    </Box>
}


export const QuestionContent = withCookies(connect(null,
{ answerQuestion, nextQuestion }
)(QuestionContentComponent));
  
export default Question
